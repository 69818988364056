@font-face {
  font-family: BLKBSans;
  src: url("https://sky.blackbaudcdn.net/static/skyux-fonts/2.0.0/assets/BLKBSans-Regular.woff");
}

body {
  margin: 0;
  font-family: BLKBSans, "Segoe UI", "Roboto", "Cantarell", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.parent-container .module-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.loading-parent-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.parent-wrapper {
  padding-left: 15px;
}

.module-container .stats-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 25px;
}

.ant-table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #fbfbfb;
}

.disabled-module-container {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled-module-container div {
  max-width: 400px;
}

.text-light {
  color: #696c73;
}

.text-dark {
  color: #212327;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: 600;
}

.text-size-regular {
  font-size: 15px;
  margin: 0;
}

.text-size-heading-3 {
  font-size: 19px;
  font-weight: 300;
  color: #696c73;
  margin-bottom: 10px;
  margin-top: 20px;
}

.text-size-display-3 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.text-emphasized {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.link {
  color: #0974a1;
  text-decoration: none;
}

.ant-pagination .ant-pagination-item {
  border: 1px solid #cdcfd2;
  margin: 0;
  border-radius: unset;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #cdcfd2;
}

.ant-pagination .ant-pagination-item-active > a {
  color: unset !important;
}

.ant-pagination .ant-pagination-item-active:hover {
  border: 1px solid #cdcfd2;
}

.ant-pagination-next,
.ant-pagination-prev {
  background-color: #fff;
  border: 1px solid #cdcfd2;
  border-radius: 0 !important;
  margin-inline-end: 0 !important;
}

.ant-table-content thead th {
  border-right: 1px solid #cdcfd2 !important;
  border-start-end-radius: 0 !important;
  border-start-start-radius: 0 !important;
  background-color: #fff !important;
}

.ant-table-content thead th:nth-child(2) {
  border-right: none !important;
}

.ant-table-content tbody td {
  border-inline-end: unset !important;
}

.ant-table-content thead th {
  border-top: 1px solid #cdcfd2 !important;
  border-bottom: 1px solid #cdcfd2 !important;
}

.ant-table-content tbody td {
  border-bottom: 1px dotted #cdcfd2 !important;
}

.no-activity-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-divider {
  margin: 40px 0 24px 0;
}

.engagement-table-container .ant-table-container {
  border-inline-start: none !important;
}
.engagement-table-container table {
  font-family: BLKBSans, "Segoe UI", "Roboto", "Cantarell", sans-serif !important;
}

.engagement-table-container .ant-table-cell {
  padding: 7px 16px !important;
}

.logo-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container p {
  margin: 0;
  font-size: 15px;
  color: #696c73;
  margin-right: 8px;
  position: relative;
  top: -3px;
}
